import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
   
   {
		label: t('BSC'),
		icon: 'Link2Icon',
		 
		
		
		items: [
		   
			{
			label: t('AlveyChain'),
			href: 'https://alvey.ampleswap.com/',
			},
			
			{
			label: t('DecimalChain'),
			href: 'https://decimal.ampleswap.com/',
			},
			
			
				
		],},
  

  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Swap'),
        href: '/swap?outputCurrency=0x19857937848c02afbde8b526610f0f2f89e9960d',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
		{
			label: t('Perpetual'),
			icon: 'TradeIcon',
			href: 'https://perp.ampleswap.com',
			// type: window.open("https://perp.ampleswap.com", '_blank'),
		},
    ],
  },
  
   

  /* {
    label: t('PancakeSwap'),
    icon: 'TradeIcon',
    href: 'https://pancakeswap.finance/swap?outputCurrency=0x19857937848c02afbde8b526610f0f2f89e9960d',
  }, */

  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
 /*  {
    label: t('Prediction (BETA)'),
    icon: 'PredictionsIcon',
    href: '/prediction',
  },  
   {
    label: t('Lottery'),
    icon: 'TicketIcon',
    href: '/lottery',
  }, */
  /*  {
    label: t('Collectibles'),
    icon: 'NftIcon',
    href: '/collectibles',

  }, */
  /* {
    label: t('Team Battle'),
    icon: 'TeamBattleIcon',
    href: '/competition',
  }, */
 /*  {
    label: t('Teams & Profile'),
    icon: 'GroupsIcon',
    items: [
      {
        label: t('Leaderboard'),
        href: '/teams',
      },
      {
        label: t('Task Center'),
        href: '/profile/tasks',
      },
      {
        label: t('Your Profile'),
        href: '/profile',
      },
    ],
  }, */
         
		
		
		

           		{
		label: t('More'),
		icon: 'MoreIcon',

		items: [
				{
				label: t('Info'),
				icon: 'InfoIcon',
				href: 'https://info.ampleswap.com',
				},
				
				{
				// label: t('Partnership'),
				// icon: 'PartnershipIcon',
				// href: 'https://docs.ampleswap.com/business-partnerships',
				label: t('Governance'),
				icon: 'Link3Icon',
				href: 'https://snapshot.org/#/ampleswap.eth',
				},
				
				{
				label: t('Docs'),
				icon: 'InfoIcon',
				href: 'https://docs.ampleswap.com/',
				}, 
				
				{
				label: t('Blog'),
				icon: 'InfoIcon',
				href: 'https://ampleswap.medium.com/',
				},
				
				{
				label: t('Audit'),
				icon: 'InfoIcon',
				href: 'https://ampleswap.com/audit-by-etherauthority.pdf',
				},  
				
				
				
				
		],},
			
		
		
		



]

export default config

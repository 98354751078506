import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
    {
    sousId: 0,
    stakingToken: tokens.ample,
    earningToken: tokens.ample,
    contractAddress: {
      97: '0xf5987603323aa99dde0777a55e83c82d59cca272',
      56: '0xf5987603323aa99dde0777a55e83c82d59cca272',
    },
    poolCategory: PoolCategory.CORE,    
    harvest: true,
    tokenPerBlock: '30',
    sortOrder: 1,
    isFinished: false,
    },
  
    {
    sousId: 51,
    stakingToken: tokens.amplebnblp,
    earningToken: tokens.ample,
    contractAddress: {
      97: '',
      56: '0x968437903c0370f823060767f670189a7a9145f3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0311',  //  200 starting 0.0048 token pe block (now 1200 per block )  
    },
	
    
	{
    sousId: 50,
    stakingToken: tokens.ample,
    earningToken: tokens.walv,
    contractAddress: {
      97: '',
      56: '0x183a39b2c5dcf9ecbdeda088d5f95f79d03f5741',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.011',  // curent 0.011 
    },
 

 
// *************   lp pool manage old SmartChefFactory contract ******************* 
	
	
	

// *********************************** finished *************************
//   last 50 new 51
  {
    sousId: 32,
    stakingToken: tokens.amplebnblp,
    earningToken: tokens.ample,
    contractAddress: {
      97: '',
      56: '0xe6078db33c9c8f80c0d141a170a98250c9c333ab',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0185',  //  240  token 
    },
	
	{
	sousId: 43,
    stakingToken: tokens.ample,
    earningToken: tokens.tifi,
    contractAddress: {
      97: '',
      56: '0x9a76aae91622ed11db6a28db883e05c23df3523c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '17001',   // 1
    },
	
	{
    sousId: 48,
    stakingToken: tokens.ample,
    earningToken: tokens.flash,
    contractAddress: {
      97: '',
      56: '0x618699c7d848b1bad49494ab2e68f4f9fe09fb2a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '23',  
    },
  
  {
    sousId: 45,
    stakingToken: tokens.ample,
    earningToken: tokens.walv,
    contractAddress: {
      97: '',
      56: '0x564e542ea25c480d6cb3b8ed302b0a0ccfca3b91',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.034',  
    },
	
	{
    sousId: 49,
    stakingToken: tokens.ample,
    earningToken: tokens.mrsh,
    contractAddress: {
      97: '',
      56: '0x2e4ba3c722d6e29da6199098d3afbc7ed77b76a9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '400',  
    },
	
	{
    sousId: 44,
    stakingToken: tokens.ample,
    earningToken: tokens.frz,
    contractAddress: {
      97: '',
      56: '0x29943b60f0b3737554f56b6e08ab461d04d2587d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '105',  
    },
  
	
	
  {
    sousId: 41,
    stakingToken: tokens.ample,
    earningToken: tokens.flash,
    contractAddress: {
      97: '',
      56: '0x72cd880fbcdfb38fb8c32d99fdbc260463305d30',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '28',  
    },
  {
    sousId: 40,
    stakingToken: tokens.ample,
    earningToken: tokens.xcn,
    contractAddress: {
      97: '',
      56: '0x6510e981fc5af1348bd5e6d3235effa60b827c85',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1',  
    }, 
  {
    sousId: 38,
    stakingToken: tokens.ample,
    earningToken: tokens.walv,
    contractAddress: {
      97: '',
      56: '0x25d786fa34a2340a005c2d756a0cac197160a756',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0328048',  
    },

  {
	sousId: 23,
    stakingToken: tokens.ample,
    earningToken: tokens.tifi,
    contractAddress: {
      97: '',
      56: '0xb9243f3256c44adf8a0b616d6cc9fe3f320f1fc2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '23158',   // 1
    },
	
  {
    sousId: 39,
    stakingToken: tokens.ample,
    earningToken: tokens.bles,
    contractAddress: {
      97: '',
      56: '0xc5adddb6a0d683f7af2d03107a01095c6555d465',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.5',  
    }, 	
	
	{
    sousId: 36,
    stakingToken: tokens.ample,
    earningToken: tokens.jet,
    contractAddress: {
      97: '',
      56: '0x3f95a34764d45637dbe02149543d24c9fd1396dd',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.06487',  // 1.8
    },
	
	{
    sousId: 35,
    stakingToken: tokens.ample,
    earningToken: tokens.boai,
    contractAddress: {
      97: '',
      56: '0x6298a76d564d8599dd290e1b765d781009ac7076',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.77',   
    },
  
  {
    sousId: 37,
    stakingToken: tokens.ample,
    earningToken: tokens.mshiba,
    contractAddress: {
      97: '',
      56: '0x4271177621aa5d2bc3cf846518d16f3035ed2b7c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2321083',  // 2321083
    },
	
	
  {
    sousId: 30,
    stakingToken: tokens.ample,
    earningToken: tokens.walv,
    contractAddress: {
      97: '',
      56: '0x53fe5eeb0c994cd570232b7c9574551c0b0997ae',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.035',  
    },

  {
    sousId: 29,
    stakingToken: tokens.ampleusdtlp,
    earningToken: tokens.ample,
    contractAddress: {
      97: '',
      56: '0x27e2d39a94d533ab07ef3dd05879e78ce8d4a972',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.211953', // 60
  },
  
  {
    sousId: 25,
    stakingToken: tokens.amplebnbpancakelp,
    earningToken: tokens.ample,
    contractAddress: {
      97: '',
      56: '0xf83b4e450c4d4b304d5d6c4c8eb856437b400c34',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.011722', // 45
  },
  
  {
    sousId: 14,
    stakingToken: tokens.walv,
    earningToken: tokens.ample,
    contractAddress: {
      97: '',
      56: '0xaec6af2a3eacb1299e7ac52027421c83ba0de9ed',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0013',   // 0.45  450000000000000000 
    },
  
 
	
	{
    sousId: 33,
    stakingToken: tokens.boaibnblp,
    earningToken: tokens.boai,
    contractAddress: {
      97: '',
      56: '0x697f97316e35f027cbeb627246bf5ab8db225802',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0000451',  // 1.8
    },
	
	{
    sousId: 5,
    stakingToken: tokens.cubybnblp,
    earningToken: tokens.cuby,
    contractAddress: {
      97: '',
      56: '0xddb5cf6b06fd6bdc838e2e672a1630c78efa5de8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '2.28', // 450
    }, 
  
  {
    sousId: 28,
    stakingToken: tokens.ample,
    earningToken: tokens.wbbc,
    contractAddress: {
      97: '',
      56: '0x31cFE1A2658290493603dECf82C865811aC6F077',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.24',  
    },	

   {
    sousId: 13,
    stakingToken: tokens.ample,
    earningToken: tokens.frtc,
    contractAddress: {
      97: '',
      56: '0x8c8bd573201cfd49f0a24e9750b06899b65f6bcf',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '6222',  
    },	

	{
    sousId: 9,
    stakingToken: tokens.ample,
    earningToken: tokens.poss,
    contractAddress: {
      97: '',
      56: '0x5cf4c1abf95ca3dd72f19c2a45c4e02ef6c1f0dd',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.19379844961',  
    },

   {
    sousId: 10,
    stakingToken: tokens.ample,
    earningToken: tokens.walv,
    contractAddress: {
      97: '',
      56: '0x2677c1fdee59cbfd4c9b7cfbaca301bbbe5bf43b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.028',  
    },
	
	{
    sousId: 19,
    stakingToken: tokens.wbnb,
    earningToken: tokens.ample,
    contractAddress: {
      97: '',
      56: '0x6b5c2befb77e7deca23bd025ca29a66e061d3464',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00000011',   // 0.30   300000000000000000
    },
    
	{
    sousId: 7,
    stakingToken: tokens.ample,
    earningToken: tokens.sweep,
    contractAddress: {
      97: '',
      56: '0x90d16158d198f08df4e8457e52969e93cc029197',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '22175732',  
    },
	
	{
    sousId: 8,
    stakingToken: tokens.ample,
    earningToken: tokens.dogeum,
    contractAddress: {
      97: '',
      56: '0x190917aee71525bc2665ac860e8f8c87c6175e7a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '4915',  
    },
  
   
    {
	sousId: 4,
	stakingToken: tokens.ample,
	earningToken: tokens.cuby,
	contractAddress: {
	97: '',
	56: '0x6a011c182e9cbbbae5ea2f82949cf20739463cdd',
	},
	poolCategory: PoolCategory.CORE,
	harvest: true,
	sortOrder: 999,
	tokenPerBlock: '747',  
	},
	
	
	
	{
	sousId: 20,
    stakingToken: tokens.ample,
    earningToken: tokens.tifi,
    contractAddress: {
      97: '',
      56: '0x61e8de0cc1a4134a5b59672706ad0f3e6f27c987',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '34948',   // 1
    },

	
	


	

]

export default pools

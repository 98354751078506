import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252,replace 0,1,2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'AMPLE',
    lpAddresses: {
      97: '0x19857937848c02afbde8b526610f0f2f89e9960d',
      56: '0x19857937848c02afbde8b526610f0f2f89e9960d',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  
  
 
  {
    pid: 1,
    lpSymbol: 'AMPLE-BNB LP',
    lpAddresses: {
      97: '0x471a6df20130f0559e931386e47d65fff31dd7aa',
      56: '0x471a6df20130f0559e931386e47d65fff31dd7aa',
    },
    token: tokens.ample,
    quoteToken: tokens.wbnb,
  },
  
  
	
	{
	pid: 58,
	lpSymbol: 'BTCB-ETH LP',
	lpAddresses: {
	97: '',
	56: '0x31a5f79c79186314365a94e557f5712c5913f45e',
	},
	token: tokens.btcb,
	quoteToken: tokens.eth,
	},
	
	{
	pid: 12,
	lpSymbol: 'USDC-USDT LP',
	lpAddresses: {
	97: '',
	56: '0x861f685da59c51909e5b54d81404b073d35adf9b',
	},
	token: tokens.usdc,
	quoteToken: tokens.usdt,
	},
	
	{
	pid: 9,
	lpSymbol: 'USDT-BNB LP',
	lpAddresses: {
	97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
	56: '0xe889c835030a21b12656d7799de8326c1560198e',
	},
	token: tokens.usdt,
	quoteToken: tokens.wbnb,
	},

	{
	pid: 51,
	lpSymbol: 'ETH-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x6343ffa0b8716fdf325d85a9b149c7f6e4206223',
	},
	token: tokens.eth,
	quoteToken: tokens.wbnb,
	},

//   ************************* *****************  big projects  ************************************************************ 

	{
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0x529d8d8b1050191aca8e45792c811ecc1b3e8461',
      56: '0x529d8D8B1050191Aca8e45792C811EcC1B3e8461',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    },
	
	{
	pid: 54,
	lpSymbol: 'SOL-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xb17fbdc93cabebd07e3ed187ad37ef8c795524ee',
	},
	token: tokens.sol,
	quoteToken: tokens.wbnb,
	}, 
		
	{
	pid: 57,
	lpSymbol: 'ADA-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x6dfc743c6dbd77b8a4345ea9dd6295fcfc2811b7',
	},
	token: tokens.ada,
	quoteToken: tokens.wbnb,
	}, 
  
  
	{
	pid: 52,
	lpSymbol: 'LINK-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x3b4a4ad013040f61d5f5d107e9f60429f67ca65e',
	},
	token: tokens.link,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 55,
	lpSymbol: 'DOT-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x69054b8160a67815238b6ff542709b87acdfc654',
	},
	token: tokens.dot,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 56,
	lpSymbol: 'DOGE-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xdec13fe0641ae31d4f8b3e5484063ed9615c5457',
	},
	token: tokens.doge,
	quoteToken: tokens.wbnb,
	}, 
	
	
	{
	pid: 3,
	lpSymbol: 'AMPLE-BUSD * Pancake Lp V2',
	lpAddresses: {
	97: '',
	56: '0x8dabad1f1c7b51e58e0711029de4f66a6d17bd75',
	},
	token: tokens.ample,
	quoteToken: tokens.busd,
	},
	
	{
	pid: 53,
	lpSymbol: 'BTCB-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x7682c715304aa1306e79a137d017050db6937987',
	},
	token: tokens.btcb,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 11,
	lpSymbol: 'BTCB-USDT LP',
	lpAddresses: {
	97: '',
	56: '0xa79797bdbc375f798313304893917e97a7216f3b',
	},
	token: tokens.btcb,
	quoteToken: tokens.usdt,
	},
	
	{
	pid: 10,
	lpSymbol: 'ETH-USDT LP',
	lpAddresses: {
	97: '',
	56: '0xb4c5fda168e83ab37526104bb3efe53dbbb72726',
	},
	token: tokens.eth,
	quoteToken: tokens.usdt,
	},
	
	{
	pid: 39,
	lpSymbol: 'TUSD-USDT LP',
	lpAddresses: {
	97: '',
	56: '0x00a3ac683d44cecb92ffc793394fbf31c32897b4',
	},
	token: tokens.tusd,
	quoteToken: tokens.usdt,
	},
	
	{
	pid: 15,
	lpSymbol: 'DOGE-USDT LP',
	lpAddresses: {
	97: '',
	56: '0x632598eeed843ceccab1dfbf369f742db39dd1b0',
	},
	token: tokens.doge,
	quoteToken: tokens.usdt,
	},
	
	{
	pid: 8,
	lpSymbol: 'BUSD-USDT LP',
	lpAddresses: {
	97: '',
	56: '0x967f8f1cf7cb261e9c45979261813a33da043d9d',
	},
	token: tokens.busd,
	quoteToken: tokens.usdt,
	},
	
	{
	pid: 13,
	lpSymbol: 'LINK-USDT LP',
	lpAddresses: {
	97: '',
	56: '0x3fd00f6360069520d9b837e0e463305ff292147b',
	},
	token: tokens.link,
	quoteToken: tokens.usdt,
	},

	{
	pid: 14,
	lpSymbol: 'ADA-USDT LP',
	lpAddresses: {
	97: '',
	56: '0x74e171d4abae1fbd6fed15fecf6eb2447510b605',
	},
	token: tokens.ada,
	quoteToken: tokens.usdt,
	},
	
	
	

//   ************************* ***************** Client Project  ************************************************************
	{
	pid: 7,
	lpSymbol: 'wALV-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x01264f5e9bae17702bc385cabc073155c6352b96',
	},
	token: tokens.walv,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 50,
	lpSymbol: 'OK-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xae0a7ace5af2b2aef07913fef52b644bab6b0323',
	},
	token: tokens.ok,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 46,
	lpSymbol: 'FLASH-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x30fa1fcb55bd68195980f31af32a732464fff83c',
	},
	token: tokens.flash,
	quoteToken: tokens.wbnb,
	}, 

	{
	pid: 48,
	lpSymbol: 'AMPLE-FLASH LP',
	lpAddresses: {
	97: '',
	56: '0x919c035fef8141f995f4a151488c157499fa8662',
	},
	token: tokens.ample,
	quoteToken: tokens.flash,
	}, 
	
	
	
//   ************************* ***************** finished farm  ************************************************************
//   16 pid is AMPLE-BNB babydogeswap 
//   USDC-BNB Lp 24  0x60a08a1f98e0578b3e6fa3e857fc058927453fbf
//   USDC-BUSD Lp 25  0xf3b65172907b18d4f8b3ec9b430bf160f2bfa179
//   ETH-WBNB Lp 26  0x6343ffa0b8716fdf325d85a9b149c7f6e4206223
//   pid: 29  AMPLE-BUSD * Pancake Lp V3  0x46a15b0b27311cedf172ab29e4f4766fbe7f4364   not work
//   USDT-BNB * Pancake Lp V2  30
//   AMPLE-BNB LP Pancakeswap 33  
//   DAF-BNB LP Pancakeswap 34  
//   CROOGE-BNB LP Pancakeswap 35  
//   last pid is 37 new 38 
//   pid is 38 AMPLE-USDT 
//   last pid is 71 new 72
     
    /* {
    pid: 17,
	lpSymbol: 'AMPLE-USDT * BabyDogeSwap Lp',
	lpAddresses: {
	97: '',
	56: '0xef580cf1a0527c34886fb450e3d7805b92654f5b',
	},
	token: tokens.ample,
	quoteToken: tokens.usdt,
  }, */
  
  
  //  ****************new farm ***********************
   {
	pid: 22,
	lpSymbol: 'TIFI-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x194a2c3e917fbfe789c2f39c75f14954b2c969ad',
	},
	token: tokens.tifi,
	quoteToken: tokens.wbnb,
	},
   
   {
	pid: 49,
	lpSymbol: '4RZ-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x6a362fdcd3394a53faab30d76909db91ef9c23ba',
	},
	token: tokens.frz,
	quoteToken: tokens.wbnb,
	},

    {
	pid: 71,
	lpSymbol: 'MRSH-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xffa3879a7829649b350b2bcfa3e3e6ad576ed3f2',
	},
	token: tokens.mrsh,
	quoteToken: tokens.wbnb,
	},	
   {
	pid: 61,
	lpSymbol: 'MTC-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x37d50d2ba509ebd3e651ad2e67ec01fba7393141',
	},
	token: tokens.mtc,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 62,
	lpSymbol: 'FRGX-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x89f1e2c903c5e1d713bc7bfce89c46266f71c2b7',
	},
	token: tokens.frgx,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 63,
	lpSymbol: 'BURN-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x7b58b795d66f7df29dbfbf7af1a9ebb028a764b7',
	},
	token: tokens.burn,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 64,
	lpSymbol: 'METFI-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x195306d8d78dbb51bc0e03b1b1919c8a7108c15e',
	},
	token: tokens.metfi,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 65,
	lpSymbol: 'HI-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x88834810871e6effbe76272daeebed85a9231392',
	},
	token: tokens.hi,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 66,
	lpSymbol: 'SAKAI-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x596f17223d324fe74d037335a3b766ab346fcabc',
	},
	token: tokens.sakai,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 67,
	lpSymbol: 'AET-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xeea492c3747cf864bf7af7a87cc0120db56ba626',
	},
	token: tokens.aet,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 68,
	lpSymbol: 'FINC-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x00089d582e920282589c12da1b73bdfae5d75fb3',
	},
	token: tokens.finc,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 69,
	lpSymbol: 'PLSPAD-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x3df300a32adc893f4163cd715d3b8303693a73a3',
	},
	token: tokens.plspad,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 70,
	lpSymbol: 'CREO-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x4b508f696436d2db1c489ccff6b837aa41270472',
	},
	token: tokens.creo,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 59,
	lpSymbol: 'MTP-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x1d83286429f068cb95e1ebd653e05ab54162eedf',
	},
	token: tokens.mtp,
	quoteToken: tokens.wbnb,
	}, 
	
	{
	pid: 60,
	lpSymbol: 'ABG-USDT LP',
	lpAddresses: {
	97: '',
	56: '0xee20192a12c8077fdc9b05cb0e3a8e66982ff1b3',
	},
	token: tokens.abg,
	quoteToken: tokens.usdt,
	}, 
	
	

    {
	pid: 47,
	lpSymbol: 'GGH-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x69a92398658fd9e0ac43bc4df713558d37235b54',
	},
	token: tokens.ggh,
	quoteToken: tokens.wbnb,
	}, 	
     
	{
	pid: 45,
	lpSymbol: 'XCN-USDT LP',
	lpAddresses: {
	97: '',
	56: '0xcb1911dc3d9baac68603b8a309ea61076184839c',
	},
	token: tokens.xcn,
	quoteToken: tokens.usdt,
	},
	 
	{
	pid: 44,
	lpSymbol: 'BLES-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x4a28407692b926a104b6e4b04052e27a1e221459',
	},
	token: tokens.bles,
	quoteToken: tokens.wbnb,
	}, 
	
    {
	pid: 43,
	lpSymbol: 'MSHIBA-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x074993a77d8d05f3dad7291cc27c010bda92dd7f',
	},
	token: tokens.mshiba,
	quoteToken: tokens.wbnb,
	},
	
    {
	pid: 42,
	lpSymbol: 'JET-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x45e7749b894dd251b38e741869069788a0f73897',
	},
	token: tokens.jet,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 40,
	lpSymbol: 'GHDS-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x2844363D6C785998c3cfdEA25fa9917f6952b71A',
	},
	token: tokens.ghds,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 41,
	lpSymbol: 'BOAI-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xca07b5d93601c55de07425072b9c573628ba8e2b',
	},
	token: tokens.boai,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 36,
	lpSymbol: 'ZBC-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x9d545F4f56BCE382e7089dC6286b00ABF36EaA24',
	},
	token: tokens.zbc,
	quoteToken: tokens.wbnb,
	},
	
	
	
	{
	pid: 37,
	lpSymbol: 'WBBC-USDT LP',
	lpAddresses: {
	97: '',
	56: '0x298aadf98b9a6220080925489b5c1e8d8057a602',
	},
	token: tokens.wbbc,
	quoteToken: tokens.usdt,
	},
	
	{
	pid: 21,
	lpSymbol: 'FRTC-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xa6fa80286d871a11aca06183e51002c4c8f27874',
	},
	token: tokens.frtc,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 31,
	lpSymbol: 'NSDX-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x26607FEa66D811d2FA2388CA8A542b43Fd0ed2A1',
	},
	token: tokens.nsdx,
	quoteToken: tokens.wbnb,
	},
	
    {
	pid: 20,
	lpSymbol: 'POSS-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xf76e923960b196d89a2f414dce89861cec09dff9',
	},
	token: tokens.poss,
	quoteToken: tokens.wbnb,
	},
	{
	pid: 32,
	lpSymbol: 'CROOGE-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x109c55b96f8546200f0aa553241ac02a2c86b1e4',
	},
	token: tokens.crooge,
	quoteToken: tokens.wbnb,
	},
	 
	
	{
	pid: 23,
	lpSymbol: 'ETH-USDC LP',
	lpAddresses: {
	97: '0x8b165a7a50456353419ee69a83f8fe747aaaacb8',
	56: '0x8b165a7a50456353419ee69a83f8fe747aaaacb8',
	},
	token: tokens.eth,
	quoteToken: tokens.usdc,
	},
	
	
	
	{
	pid: 4,
	lpSymbol: 'ACE-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xb950e5914a1e168420e74701ae20e75880915bde',
	},
	token: tokens.ace,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 18,
	lpSymbol: 'SWEEP-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xf34d167cca169e7b3247b0d0b49111534b725e36',
	},
	token: tokens.sweep,
	quoteToken: tokens.wbnb,
	},
	{
	pid: 6,
	lpSymbol: 'CUBY-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x4b7e5fc3178d0728a9e3782086cebaa8839ba340',
	},
	token: tokens.cuby,
	quoteToken: tokens.wbnb,
	},
    {
	pid: 27,
	lpSymbol: 'YIN-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xb5bf17625c4861302283784739f8316c9b2eb5ee',
	},
	token: tokens.yin,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 28,
	lpSymbol: 'YANG-BNB LP',
	lpAddresses: {
	97: '',
	56: '0xd3247797fe06f909bcf2cdddd70faedfeede07a8',
	},
	token: tokens.yang,
	quoteToken: tokens.wbnb,
	}, 
	{
	pid: 19,
	lpSymbol: 'DOGEUM-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x6fe54c89a98ad887961214c783164d0f57e7bdc1',
	},
	token: tokens.dogeum,
	quoteToken: tokens.wbnb,
	},
	
	{
	pid: 5,
	lpSymbol: 'BENX-USDT LP',
	lpAddresses: {
	97: '',
	56: '0xefa308095c172739691736b880b96c9e7cc3b930',
	},
	token: tokens.benx,
	quoteToken: tokens.usdt,
    },   
   
    {
	pid: 34,
	lpSymbol: 'DAF-BNB LP',
	lpAddresses: {
	97: '',
	56: '0x6a4ae43836167528cb279c857d79127723647d50',
	},
	token: tokens.daf,
	quoteToken: tokens.wbnb,
	}, 

]

export default farms

import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}
export const WBNB = new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
)

const tokens = {
		bnb: {
		symbol: 'BNB',
		projectLink: 'https://www.binance.com/',
		},
		ample: {
		symbol: 'AMPLE',
		address: {
		56: '0x19857937848c02afbde8b526610f0f2f89e9960d',
		97: '0x19857937848c02afbde8b526610f0f2f89e9960d',
		},
		decimals: 18,
		projectLink: 'https://ampleswap.com/',
		},
		
		eth: {
		symbol: 'ETH',
		address: {
		56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
		97: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
		},
		decimals: 18,
		projectLink: 'https://ethereum.org/en/',
		},
		
		btcb: {
		symbol: 'BTCB',
		address: {
		56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
		97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
		},
		decimals: 18,
		projectLink: 'https://bitcoin.org/',
		},
		
		usdc: {
		symbol: 'USDC',
		address: {
		56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
		97: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
		},
		decimals: 18,
		projectLink: 'https://www.centre.io/usdc',
		},
		
		link: {
		symbol: 'LINK',
		address: {
		56: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
		97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
		},
		decimals: 18,
		projectLink: 'https://chain.link/',
		},
		
		ada: {
		symbol: 'ADA',
		address: {
		56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
		97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
		},
		decimals: 18,
		projectLink: 'https://www.cardano.org/',
		},
		
		doge: {
		symbol: 'DOGE',
		address: {
		56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
		97: '',
		},
		decimals: 8,
		projectLink: 'https://dogecoin.com/',
		},
		
		daf: {
		symbol: 'DAF',
		address: {
		56: '0x8Fb1a59cA2d57B51e5971A85277EFe72c4492983',
		97: '',
		},
		decimals: 6,
		projectLink: 'https://daftoken.io/',
		},
		
		dafbnblp: {
		symbol: 'DAF-BNB LP',
		address: {
		56: '0x6a4ae43836167528cb279c857d79127723647d50',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://daftoken.io/',
		},
		
		amplebnbpancakelp: {
		symbol: 'AMPLE-BNB PANCAKE LP V2',
		address: {
		56: '0xd587b574b6dffef12e7bee23c1ebda08ab9f14fe',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://ampleswap.com/',
		},

        amplebnblp: {
		symbol: 'AMPLE-BNB LP',
		address: {
		56: '0x471a6df20130f0559e931386e47d65fff31dd7aa',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://ampleswap.com/',
		}, 		
		
		ampleusdtlp: {
		symbol: 'AMPLE-USDT LP',
		address: {
		56: '0x40f3beeeae82fb0e5e0ed038ff9b567c79ea5ab2',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://ampleswap.com/',
		}, 
		
		croogebnblp: {
		symbol: 'CROOGE-BNB PANCAKE LP V2',
		address: {
		56: '0xb1bef3b0eb6065600c44992896ee1e5e3867462c',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://scroogecoin.org/',
		}, 
        
		nsdxbnblp: {
		symbol: 'NSDX-BNB LP',
		address: {
		56: '0x26607fea66d811d2fa2388ca8a542b43fd0ed2a1',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.nasdex.xyz/',
		}, 
		
		cubybnblp: {
		symbol: 'CUBY-BNB LP',
		address: {
		56: '0x4b7e5fc3178d0728a9e3782086cebaa8839ba340',
		97: '0x4b7e5fc3178d0728a9e3782086cebaa8839ba340',
		},
		decimals: 18,
		projectLink: 'https://cubyield.com/',
		}, 

		brise: {
		symbol: 'BRISE',
		address: {
		56: '0x8FFf93E810a2eDaaFc326eDEE51071DA9d398E83',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://bitgert.com/',
		},
		
		benx: {
		symbol: 'BENX',
		address: {
		56: '0x315bE92AbA5C3AaAf82b0C0C613838342c1416E7',
		97: '',
		},
		decimals: 7,
		projectLink: 'https://bluebenx.com/',
		},
		
		cuby: {
		symbol: 'CUBY',
		address: {
		56: '0x488907d99cE2ac919A47AcFac896e825Fd23598f',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://cubyield.com/',
		},

		walv: {
		symbol: 'wALV',
		address: {
		56: '0x256D1fCE1b1221e8398f65F9B36033CE50B2D497',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.alveychain.com/',
		},

		ace: {
		symbol: 'ACE',
		address: {
		56: '0xfaaa87943bfca6d97434be3d26c589647fea4375',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://aceholdingsinc.com',
		},
		
		sweep: {
		symbol: 'SWEEP',
		address: {
		56: '0x09c704c1EB9245Af48f058878e72129557a10F04',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://sweepproject.com/',
		},
		
		dogeum: {
		symbol: 'DOGEUM',
		address: {
		56: '0xE83981C6E294881D92697FdC887D19Acd9A820E3',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://dogeum.io/',
		},
		
		poss: {
		symbol: 'POSS',
		address: {
		56: '0x230f6e7904FFc156Abd8aDfd7556e56E2A358cB1',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://posschain.com/',
		},
		
		frtc: {
		symbol: 'FRTC',
		address: {
		56: '0x88B985007d714d1578BCcdEc2303212c14946cdC',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://fartcoin.gold/',
		},
		
		mir: {
		symbol: 'MIR',
		address: {
		56: '0x2134f3A7b18aE4161fBaB6EcCCa7497E17a6777b',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://mir-token.com/',
		},
		
		tifi: {
		symbol: 'TIFI',
		address: {
		56: '0x17E65E6b9B166Fb8e7c59432F0db126711246BC0',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://tifi.net/',
		},
		
		yin: {
		symbol: 'YIN',
		address: {
		56: '0x5d5dC8e4D680FC3eF33986292eB02315ab05c735',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://yinyangreflect.com/#/',
		},
		
		yang: {
		symbol: 'YANG',
		address: {
		56: '0xC486312D5158315C7Bbe2C353ad565d1A9d1f0de',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://yinyangreflect.com/#/',
		},
		
		nsdx: {
		symbol: 'NSDX',
		address: {
		56: '0x417871f0682Db63924B931Fc16ba3Ff40343Cdcb',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.nasdex.xyz/',
		},
		
		crooge: {
		symbol: 'CROOGE',
		address: {
		56: '0xb72E9E91a4A15CF5aa37b9A8Ae224EbB631bA14f',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://scroogecoin.org/',
		},
		
		zbc: {  
		symbol: 'ZBC',
		address: {
		56: '0x37a56cdcD83Dce2868f721De58cB3830C44C6303',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://zebec.io/',
		},
		
		wbbc: {
		symbol: 'WBBC',
		address: {
		56: '0x849b8cA35485d28B8fe4EE8d695D98491e024024',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://butane.tech/',
		},
		
		
		tusd: {
		symbol: 'TUSD',
		address: {
		56: '0x40af3827F39D0EAcBF4A168f8D4ee67c121D11c9',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://trueusd.com/',
		},
		
		ghds: {
		symbol: 'GHDS',
		address: {
		56: '0xa9dcabcfcd0923d292b1ebdefd8c2a5188b13c77',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://giftedhands.exchange/',
		},
		
		boai: {
		symbol: 'BOAI',
		address: {
		56: '0x571dbcaa3df80dfebf69fefd084dace4a22ea7bf',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://bolicai.space/',
		},
		
		boaibnblp: {
		symbol: 'BOAI-BNB LP',
		address: {
		56: '0xca07b5d93601c55de07425072b9c573628ba8e2b',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://bolicai.space/',
		},
		
		jet: {
		symbol: 'JET',
		address: {
		56: '0xc5B43669a899C5DA2A98b2bf8c9082d5e8D5EE0d',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://jetcoin.io/',
		},
		
		jetbnblp: {
		symbol: 'JET-BNB LP',
		address: {
		56: '0x45e7749b894dd251b38e741869069788a0f73897',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://jetcoin.io/',
		},
		
		mix: {
		symbol: 'MX',
		address: {
		56: '0xef9d8e6918e4de524cb768057cd44374e449aa8e',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://mushee.xyz/',
		},
		
		mshiba: {
		symbol: 'MSHIBA',
		address: {
		56: '0xbd4b29918d92d613b019252091ab0189f354534f',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.matsurishibainu.com/',
		},
        
		bles: {
		symbol: 'BLES',
		address: {
		56: '0x393d87e44c7b1f5ba521b351532c24ece253b849',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://bles.trade/',
		},
		
		xcn: {
		symbol: 'XCN',
		address: {
		56: '0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://onyx.org/',
		},
		
		flash: {
		symbol: 'FLASH',
		address: {
		56: '0xaf23921f146c1deb5b436deb3a7fdeaf53f02b3f',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://theflashtoken.xyz/',
		},
		
		ggh: {
		symbol: 'GGH',
		address: {
		56: '0xb4cb54a2078816516ea1b176fba58b0a0bde815f',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://greenhopper.io/',
		},
		
		frz: {
		symbol: '4RZ',
		address: {
		56: '0x57a5aa266107261c2f497c8b4047d5299824d3cb',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://4realza.org/',
		},
		
		ok: {
		symbol: 'OK',
		address: {
		56: '0x523821d20a283d955f6205b4c9252779cd0f964b',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://okcash.co/',
		},
		
		sol: {
		symbol: 'SOL',
		address: {
		56: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://solana.com/',
		},
		
		dot: {
		symbol: 'DOT',
		address: {
		56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://polkadot.network/',
		},
		
		mtp: {
		symbol: 'MTP',
		address: {
		56: '0xb3088f5c7ec3cd2ea2fa85d6e12c60e3a3ecf408',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://macroprotocol.com/',
		},
		
		abg: {
		symbol: 'ABG',
		address: {
		56: '0x7a4cdf532be7e65cd776c987013727a47fb199e7',
		97: '',
		},
		decimals: 8,
		projectLink: 'https://abitcoingold.com/',
		},
		
		mtc: {
		symbol: 'MTC',
		address: {
		56: '0x67009eb16ff64d06b4f782b3c552b924b1d1bb93',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://matrixchain.space/',
		},
		
		frgx: {
		symbol: 'FRGX',
		address: {
		56: '0xc703da39ae3b9db67c207c7bad8100e1afdc0f9c',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://frgx.finance/',
		},
		
		burn: {
		symbol: 'burn',
		address: {
		56: '0x19c018e13cff682e729cc7b5fb68c8a641bf98a4',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://burnedfi.com/',
		},
		
		metfi: {
		symbol: 'METFI',
		address: {
		56: '0x3e7f1039896454b9cb27c53cc7383e1ab9d9512a',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://metfi.io/',
		},
		
		hi: {
		symbol: 'HI',
		address: {
		56: '0x77087ab5df23cfb52449a188e80e9096201c2097',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://hi.com/',
		},
		
		sakai: {
		symbol: 'SAKAI',
		address: {
		56: '0x43b35e89d15b91162dea1c51133c4c93bdd1c4af',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://sakaivault.io/',
		},
		
		aet: {
		symbol: 'AET',
		address: {
		56: '0x2aa7e601a67d9c57bada24e186632539663b4945',
		97: '',
		},
		decimals: 18,
		projectLink: '',
		},
		
		finc: {
		symbol: 'FINC',
		address: {
		56: '0xa856098dcbc1b2b3a9c96c35c32bc4f71e49aed2',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://finceptor.app/',
		},
		
		plspad: {
		symbol: 'PLSPAD',
		address: {
		56: '0x8a74bc8c372bc7f0e9ca3f6ac0df51be15aec47a',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://pulsepad.io/',
		},
		
		creo: {
		symbol: 'CREO',
		address: {
		56: '0x9521728bf66a867bc65a93ece4a543d817871eb7',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.creoengine.com/',
		},
		
		hedpay: {
		symbol: 'Hdp-B',
		address: {
		56: '0x34c1433F5c547bEB6174D0bbba8da7Fdc4e81c1c',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://hedpay.com/',
		},
		
		mrsh: {
		symbol: 'MRSH',
		address: {
		56: '0xB15A34DB9c3Ae3f88B3C7d4C16366cD12550318c',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://marshmallowcoin.io/',
		},
   
 // ***************************************** default token   ********************************** 
 
		cake: {
		symbol: 'CAKE',
		address: {
		56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
		97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},
 
		wbnb: {
		symbol: 'wBNB',
		address: {
		56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
		97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},

		busd: {
		symbol: 'BUSD',
		address: {
		56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.paxos.com/busd/',
		},

		syrup: {
		symbol: 'SYRUP',
		address: {
		56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
		97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},

		usdt: {
		symbol: 'USDT',
		address: {
		56: '0x55d398326f99059fF775485246999027B3197955',
		97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
		},
		decimals: 18,
		projectLink: 'https://tether.to/',
		},

		kalm: {
		symbol: 'KALM',
		address: {
		56: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://kalmar.io/',
		},

		hotcross: {
		symbol: 'HOTCROSS',
		address: {
		56: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.hotcross.com/',
		},

		hzn: {
		symbol: 'HZN',
		address: {
		56: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://horizonprotocol.com/',
		},

		belt: {
		symbol: 'BELT',
		address: {
		56: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://beta.belt.fi/',
		},

		watch: {
		symbol: 'WATCH',
		address: {
		56: '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://yieldwatch.net/',
		},

		bry: {
		symbol: 'BRY',
		address: {
		56: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://berrydata.co/',
		},

		wsote: {
		symbol: 'wSOTE',
		address: {
		56: '0x541E619858737031A1244A5d0Cd47E5ef480342c',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://soteria.finance/#/',
		},

		helmet: {
		symbol: 'Helmet',
		address: {
		56: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.helmet.insure/',
		},

		ten: {
		symbol: 'TEN',
		address: {
		56: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.tenet.farm/',
		},

		ditto: {
		symbol: 'DITTO',
		address: {
		56: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://ditto.money/',
		},

		blink: {
		symbol: 'BLINK',
		address: {
		56: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
		97: '',
		},
		decimals: 6,
		projectLink: 'https://blink.wink.org',
		},

		qsd: {
		symbol: 'QSD',
		address: {
		56: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://chemix.io/home',
		},

		bondly: {
		symbol: 'BONDLY',
		address: {
		56: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
		97: '',
		},
		decimals: 18,
		projectLink: 'https://www.bondly.finance/',
		},

		safemoon: {
		symbol: 'SAFEMOON',
		address: {
		56: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
		97: '',
		},
		decimals: 9,
		projectLink: 'https://safemoon.net/',
		},
  
}


export default tokens
